<template>
    <div class="page">
      <ProfileManager :userId="$route.params.id"/>
    </div>
</template>

<script>
import ProfileManager from '@/components/managers/profile/ProfileManager.vue';

export default {
  name: 'Manager-id',
  components: {
    ProfileManager,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
</style>
